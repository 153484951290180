import React, { useEffect, useState, lazy, Suspense} from "react";

import { useCookies} from 'react-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import RiderClient from "./pages/client/Rider";
import {io} from "socket.io-client"

import Login from './pages/Login'
import { ThemeProvider, CssBaseline, CircularProgress,Box } from "@material-ui/core";
import Layout from "./layout/common";
import { theme } from "./theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest} from './components/video/requestReducer'
import Alerts from "./pages/alerts";
import './_helpers/extensions'
import { getExpireTime } from "./_helpers/cookies.functions";

const Home = lazy(() => import("./pages/Home"))
const Profile = lazy(() => import("./pages/Profile"))
const Bike = lazy(() => import("./pages/Bike"))
const Charts = lazy(() => import("./pages/Charts"))
const Emergency = lazy(() => import("./pages/Sos"))
const Video = lazy(() => import("./pages/Video"))
const Support = lazy(() => import("./pages/Support"))

const SuspenseComponent = ({children}) => {
  return (
    <Suspense fallback={<Box display="flex" alignItems="center" justifyContent="center"><CircularProgress color="secondary" /></Box>}>
      {children}
    </Suspense>
  )
}

export default function App() {
  const dispatch = useDispatch()
  const [cookies, setCookie,removeCookie] = useCookies(['token','userdata']);
  const [rider,setRider] = useState(null)
  const token = cookies?.token
  function Logout() {
    removeCookie("token",{path:'/'})
    return ( <Redirect to='/login' />)
  }

  useEffect(() => {
    if (token) {
      dispatch({
          type: 'requests/reset'
      })
      dispatch(fetchRequest)

      setCookie('token', token, {path: '/', expires: getExpireTime()})
      setCookie('userdata', cookies?.userdata, {path: '/', expires: getExpireTime()})

    }
  },[token])
  // const requests = useSelector(state => state.requests)
  console.log(cookies)
  useEffect(() => {
      if(cookies && cookies?.userdata && cookies?.userdata?.user) {
        RiderClient.getPortalData(cookies.userdata?.user?.email,token).then(({data})=>{
          setRider(data)
        }).catch(e=>console.log(e.message))
      } else {
        return (<Redirect to='/login' />)
      }

  },[cookies?.userdata?.user])


  useEffect(() => {
    if(cookies && cookies?.userdata) {
      setCookie('userdata', cookies?.userdata, {path: '/', expires: getExpireTime()})
    }
  }, [])
  // useEffect(() => {
  //   if (token) {
  //   dispatch({
  //       type: 'requests/reset'
  //   })
  //   dispatch(fetchRequest)
  // }
  // },[token])

  // console.log(requests)

  useEffect(() => {
    if (cookies) {
    console.log(cookies)

    const socket = io(process.env.REACT_APP_WEBSOCKET_URL??process.env.REACT_APP_API_URL,{resource:"socket"});
    socket.on("connect",() => {
        console.log(socket.connected)
    })

    socket.on("test",(data) => {
        console.log(data);
    })

    socket.on('fromServer',(data) => {
        console.log("from server",data);
        if (cookies.userdata?.user?._id === data?.uploads?.rider_id) {
          // dispatch(increment(data))
          if (data) {
            dispatch({
              type: 'requests/update',
              payload: { ... data?.uploads, download_counter: data?.download_counter}
            })
          }
          return;
        }
        return;
    });
  }
  },[])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/login">
            <Login token={cookies.token} />
          </Route>
          <Route path="/admin/logout">
            <Logout />
          </Route>
        </Switch>
        
        <Layout token={token} cookie={cookies} rider={rider}>
        <Switch>
          <Route path="/settings">
            <SuspenseComponent>
            <Profile token={cookies.token} rider={rider} />
            </SuspenseComponent>
          </Route>
          <Route path="/bike">
            <SuspenseComponent>
            <Bike token={cookies.token} rider={rider}/>
            </SuspenseComponent>
          </Route>
          <Route path="/video">
            <SuspenseComponent>
            <Video token={cookies.token} rider={rider}/>
            </SuspenseComponent>
          </Route>
          <Route path="/statistics">
            <SuspenseComponent>
              <Charts token={cookies.token} rider={rider}/>
            </SuspenseComponent>
          </Route> 
          <Route path="/sos">
            <SuspenseComponent>
              <Emergency token={cookies.token} rider={rider}/>
            </SuspenseComponent>
            </Route>
          <Route path="/alert-senstivity">
            <SuspenseComponent>
                <Alerts token={cookies.token} rider={rider} cookies={ cookies } />
            </SuspenseComponent>
          </Route>
          <Route path="/support">
            <SuspenseComponent>
                <Support />
            </SuspenseComponent>
          </Route>
          <Route path="/">
            <SuspenseComponent>
              <Home token={cookies.token} rider={rider} />
            </SuspenseComponent>
          </Route>
        </Switch>
        </Layout>
      
    </Router>
    </ThemeProvider>
  );
}

